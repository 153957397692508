<template>
<div id="page" class="w-full flex flex-col items-center">
  <div class="min-h-screen w-full md:max-w-screen-2xl">

  <div class="w-full  font-rouben text-new-white mt-60 px-8 md:px-20 flex flex-col md:flex-row">
    <div class="w-full md:w-1/2">
      <div :ref="setRef" data-anim="0" class="opacity-0 md:leading-tight tracking-wide w-full md:w-10/12 font-extrabold md:font-bold text-4xl md:text-6xl text-center md:text-left">{{contact?.kontakt?.data?.attributes?.Titel}}</div>
      <div :ref="setRef" data-anim="0" v-html="convertMarkdown(contact?.kontakt?.data?.attributes?.Text)" class="md:w-10/12 opacity-0 markdown whitespace-pre-line text-base mb-3 leading-7 mt-8 lg:mt-8 text-center md:text-left" />
    </div>
    <div class="w-full md:w-1/2 mt-24 pr-12">
      <form v-if="!submitted" :ref="setRef" data-anim="0" id="contact" class="opacity-0" @submit.prevent="submitContactForm">
        <!-- email -->
        <input id="contactEmail" name="contactEmail" v-model="contactEmail" type="email" placeholder="Email" class="w-full border-b border-current pb-2 font-semibold bg-transparent text-xl appearance-none outline-none !placeholder-new-white flex flex-row justify-center items-center !text-new-white" required/>
        <!-- name -->
        <input id="contactName" name="contactName" v-model="contactName" type="text" placeholder="Name" class="w-full mt-5 border-b border-current pb-2 font-semibold bg-transparent text-xl appearance-none outline-none !placeholder-new-white flex flex-row justify-center items-center !text-new-white" required/>
        <!-- type -->
        <select v-model="contactType" class="w-full mt-5 border-b border-current pb-2 font-semibold bg-new-black  text-xl  outline-none placeholder-new-white flex flex-row justify-center items-center" required>
          <option disabled value="">{{text.select}}</option>
          <option>{{text.common}}</option>
          <option>{{text.course}}</option>
        </select>
        <!-- message -->
        <textarea id="contactMessage" name="contactMessage" v-model="contactMessage" type="text" :placeholder="text.message" class="w-full mt-5 border-b border-current pb-2 font-semibold bg-transparent text-xl appearance-none outline-none !placeholder-new-white flex flex-row justify-center items-center !text-new-white" required/>
        <!-- submit -->
        <div class="w-full md:w-auto flex flex-row justify-start">
          <input type=submit :value=text.submit class="transition duration-500 ease-in-out tracking font-semibold text-lg md:text-lg text-new-black hover:text-new-white min-w-36 mt-3 bg-new-white px-5 py-2 rounded-md cursor-pointer flex flex-row justify-center items-center select-none group hover:bg-new-black border border-white'">
        </div>
      </form>
      <div v-if="submitted" class="text-lg">{{text.submitted}}</div>
    </div>
  </div>
    <div v-if="contact?.kontakt?.data?.attributes?.image" class="w-full px-8 md:px-20 mt-24">
      <div  class="relative bg-cover bg-no-repeat bg-center md:h-96 w-full font-rouben text-new-white" :style="{ backgroundImage: 'url(' + chooseImageFormat({ image: contact?.kontakt?.data?.attributes?.image?.data?.attributes, index: 6 }) + ')' }"></div>
    </div>
  </div>

  <queryContact />
  <div class="w-full md:flex md:flex-col md:items-center">
    <footerComponent />
  </div>
</div>
</template>

<script>
import intersectionObserver from '../mixins/intersectionObserver.vue';
import convertMarkdown from '../mixins/convertMarkdown.vue';
import chooseImageFormat from '../mixins/chooseImageFormat.vue';

// GraphQL
import queryContact from '../graphql/queryContact.vue';

// Components
import footerComponent from '../components/footer.vue';

export default {
  name: 'Kontakt',
  components: { footerComponent, queryContact },
  mixins: [chooseImageFormat, intersectionObserver, convertMarkdown],

  data() {
    return {
      contactEmail: null,
      contactName: null,
      contactMessage: null,
      contactType: '',
      submitted: false,
    };
  },

  computed: {
    contact() {
      return this.$store.state.data.contact;
    },
    text() {
      return this.$store.state.lang.contact[this.$store.state.lang.currentLang];
    },
  },

  watch: {
    contact() {
      const seo = this.contact?.kontakt?.data?.attributes?.Seo;
      document.title = seo?.metaTitle;
      document.querySelector('meta[name="description"]').setAttribute('content', seo?.metaDescription);
    },
  },

  mounted() {
    window.scrollTo({ top: 0, left: 0 });
  },

  methods: {
    submit() {
      this.$refs.form.$el.submit();
    },

    submitContactForm() {
      this.submitted = true;
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          type: this.contactType, email: this.contactEmail, name: this.contactName, message: this.contactMessage,
        }),
      };
      fetch('https://hook.eu2.make.com/cfz4yrtg777qpdxgatedd1ks2510ynva', requestOptions);
    },
  },

};
</script>
