<template>
<div>
</div>
</template>

<script>
import { useClient, useQuery } from 'villus';
import { gql } from 'graphql-tag';
import { useStore } from 'vuex';
import { computed, reactive } from 'vue';

export default {
  name: 'queryContact',
  watch: {
    contact() {
      this.$store.commit('setContact', this.contact);
    },
  },
  setup() {
    useClient({
      url: process.env.VUE_APP_GRAPHQL,
      cachePolicy: 'cache-first',
    });

    const store = useStore();

    const context = {
      headers: {
        Authorization: `Bearer ${process.env.VUE_APP_TOKEN}`,
      },
    };

    const variables = reactive({
      lang: computed(() => store.state.lang.currentLang),
    });

    const queryContact = gql`
      query ($lang:I18NLocaleCode!) {
        kontakt (locale: $lang){
          data {
            attributes {
              Titel
              Text
              image {
                data {
                    attributes {
                      url
                      formats
                    }
                  }
              }
              Seo {
                metaTitle
                metaDescription
              }
            }
          }
        }
      }
    `;
    const { data: contact } = useQuery({
      query: queryContact,
      variables,
      context,
    });
    return { contact };
  },
};
</script>
