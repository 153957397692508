<script>
export default {
  name: 'chooseImageFormat',

  data() {
    return {
      sizes: ['xsmall', 'thumbnail', 'small', 'medium', 'large', 'vlarge', 'xlarge'],
    };
  },

  methods: {
    chooseImageFormat(payload) {
      const formats = payload?.whatsonImage?.formats || payload?.thumbnail?.formats || payload?.image?.formats;
      const index = payload?.index;
      if (formats && index) {
        const { url } = formats;
        if (formats && index !== 0) {
          if (formats[this.sizes[index]]) return formats[this.sizes[index]].url;
          if (formats[this.sizes[index - 1]]) return formats[this.sizes[index - 1]].url;
          if (formats[this.sizes[index - 2]]) return formats[this.sizes[index - 2]].url;
          if (formats[this.sizes[index - 3]]) return formats[this.sizes[index - 3]].url;
          if (formats[this.sizes[index - 4]]) return formats[this.sizes[index - 4]].url;
          if (formats[this.sizes[index - 5]]) return formats[this.sizes[index - 5]].url;
          return url;
        }
        if (formats && index === 0) {
          if (formats[this.sizes[index]]) return formats[this.sizes[index]].url;
        } else if (url) {
          return url;
        }
      }
      return null;
    },

    checkUrl(url) {
      if (!url.startsWith('https://')) {
        const newUrl = `https://${url}`;
        return newUrl;
      }
      return url;
    },
  },
};
</script>
